/**
 * список роутов для навигации вне зависимости от нахождения - натив или браузер
 *
 * Структура
 * <ключ>: {
    deeplink: '<deeplink>?q1=${p1}&q2=${p2}',
    route: '<name из vue-router>',
    query: [
      <массив имен параметров для подстановки в роут vue-роутера>
    ],
    matches: [
      <массив имен параметров для подстановки в deeplink>
    ]
  },
 */
export default {
	makeDeeplink(path, params) {
		const deeplink = path?.matches
			? path?.matches.reduce((res, match) => {
					return res.replace(`\${${match}}`, params[match])
				}, path.deeplink)
			: path?.deeplink
		console.log(deeplink)
		return deeplink
	},

	BACK: {
		deeplink: 'rosfines://action/close',
		route: '_BACK_',
	},
	WIDGETLIST: {
		deeplink: 'rosfines://app/widgetlist',
	},
	ADD_CAR: {
		deeplink: 'rosfines://action/open/screen/add_car?is_registration_flow=false&type=add',
		route: 'addDocCar',
	},
	ADD_DRIVER_LICENSE: {
		deeplink: 'rosfines://action/open/screen/add_driver_license?is_registration_flow=false&type=add',
		route: 'addDocDriverLicense',
	},
	ADD_ORGANIZATION: {
		deeplink: 'rosfines://action/open/screen/add_organization',
	},
	ADD_INN: {
		deeplink: 'rosfines://action/open/screen/add_tax_docs?type=inn&is_registration=false',
	},
	ADD_PASSPORT: {
		deeplink: 'rosfines://action/open/screen/add_tax_docs?type=passport&is_registration=false',
	},
	ADD_SNILS: {
		deeplink: 'rosfines://action/open/screen/add_tax_docs?type=snils&is_registration=false',
	},
	FINE_LIST: {
		deeplink: 'rosfines://action/open/screen/fine_list',
		route: 'fineList',
	},
	FINE_DETAILS: {
		deeplink: 'rosfines://action/open/screen/fine_details?fine_id=${id}&type=FINE',
		route: 'fineItem',
		query: ['id', 'type'],
		matches: ['id', 'type'],
	},
	ORDER_DETAILS: {
		deeplink: 'rosfines://action/open/screen/fine_details?fine_id=${id}&type=ORDER',
		route: 'fineItem',
		query: ['id', 'type'],
		matches: ['id', 'type'],
	},
	TAX_DETAILS: {
		deeplink: 'rosfines://action/open/screen/tax_details?id=${id}',
		query: ['id'],
		matches: ['id'],
	},
	PREPAY: {
		deeplink: 'rosfines://action/open/screen/prepay?fine_ids=${fine_ids}&order_ids=${order_ids}&type=${type}&payment_type=${payment_type}&from=${from}&click_event=${click_event}',
		route: 'prepay',
		query: ['fine_ids', 'order_ids', 'ids', 'type', 'payment_type'],
		matches: ['fine_ids', 'order_ids', 'tax_ids', 'type', 'from', 'payment_type', 'click_event'],
	},
	PAY_BY_UIN: {
		deeplink: 'rosfines://action/open/screen/pay_by_uin',
	},
	PROFILE_DOCUMENT: {
		deeplink: 'rosfines://action/open/screen/profile_document?id=${id}&type=${type}',
		matches: ['id', 'type'],
	},
	NOTIFICATIONS: {
		deeplink: 'rosfines://action/open/screen/user_notifications?url=${url}',
		matches: ['url'],
	},
	FULLSCREEN_WEBVIEW: {
		deeplink: 'rosfines://action/webview?url=${url}',
		matches: ['url'],
	},
	FINE_PHOTO_LIST_NATIVE: {
		deeplink: 'rosfines://action/open/screen/photos?position=${position}&type=fine&photos=${photosList}',
		matches: ['position', 'photosList'],
	},
}
